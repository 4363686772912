import { useEffect as T } from "react";
const E = "referralCode", u = "ridi-referral-event", V = "ridi_app_theme", F = "ridi_nav", $ = "ridi_auth", M = "adult_exclude", j = "adult_include", z = "login_time", G = "deauth_watchdog_servertoken", H = "use_grid", B = "from_account_modify", W = "fingerprint", q = "add_pwa_requested", Q = "review-tab", X = "FIG", J = "ridi-at", Z = "ridi-rt", ee = "ridi-al", te = "user_device_type", re = "ridi_app", ne = "ridi_app_version", ie = "ridi-ffid", oe = "PHPSESSID", ce = "logout_time", ae = "__books__", se = "server_metrics", Ee = "ruid", _e = "ridi-referral-event", de = "app_instance_id", ue = "app_ga_session_id", fe = "firebase_app_id", Ie = "appsflyer_id", le = "inpay", Pe = "_rdt_info", Re = "iPod", De = "iPhone", pe = "iPad", he = "Android", me = "AndroidTab", Ae = "PC", Te = "WindowsPhone", ge = "WindowsCE", Ce = "EtcMobileDevice", Se = "PAPER", Oe = "PAPER Lite", ve = "PAPER PRO", xe = "RIDIPAPER", we = "RIDIPAPER 4";
/*! js-cookie v3.0.5 | MIT */
function l(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = arguments[r];
    for (var c in t)
      e[c] = t[c];
  }
  return e;
}
var g = {
  read: function(e) {
    return e[0] === '"' && (e = e.slice(1, -1)), e.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
  },
  write: function(e) {
    return encodeURIComponent(e).replace(
      /%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g,
      decodeURIComponent
    );
  }
};
function R(e, r) {
  function t(o, n, i) {
    if (!(typeof document > "u")) {
      i = l({}, r, i), typeof i.expires == "number" && (i.expires = new Date(Date.now() + i.expires * 864e5)), i.expires && (i.expires = i.expires.toUTCString()), o = encodeURIComponent(o).replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent).replace(/[()]/g, escape);
      var s = "";
      for (var a in i)
        i[a] && (s += "; " + a, i[a] !== !0 && (s += "=" + i[a].split(";")[0]));
      return document.cookie = o + "=" + e.write(n, o) + s;
    }
  }
  function c(o) {
    if (!(typeof document > "u" || arguments.length && !o)) {
      for (var n = document.cookie ? document.cookie.split("; ") : [], i = {}, s = 0; s < n.length; s++) {
        var a = n[s].split("="), I = a.slice(1).join("=");
        try {
          var d = decodeURIComponent(a[0]);
          if (i[d] = e.read(I, d), o === d)
            break;
        } catch {
        }
      }
      return o ? i[o] : i;
    }
  }
  return Object.create(
    {
      set: t,
      get: c,
      remove: function(o, n) {
        t(
          o,
          "",
          l({}, n, {
            expires: -1
          })
        );
      },
      withAttributes: function(o) {
        return R(this.converter, l({}, this.attributes, o));
      },
      withConverter: function(o) {
        return R(l({}, this.converter, o), this.attributes);
      }
    },
    {
      attributes: { value: Object.freeze(r) },
      converter: { value: Object.freeze(e) }
    }
  );
}
var f = R(g, { path: "/" });
const C = /^https:\/\/((next|view|preflight)\.local\.)?ridibooks\.com.*$/;
async function S({ referralEventData: e }) {
  if (!globalThis.location)
    return;
  const r = new URL(globalThis.location.href), t = Object.fromEntries(r.searchParams.entries());
  let c = !1;
  if (e) {
    const o = new Date(e.eventStartAt), n = new Date(e.eventEndAt), i = o.getTime(), s = n.getTime();
    if (!Number.isNaN(i) && !Number.isNaN(s)) {
      const a = Date.now();
      c = !(i <= a && a <= s);
    }
  }
  if (c) {
    if (t[E] || f.get(u)) {
      const { [E]: o, ...n } = t;
      f.remove(u, {
        domain: ".ridibooks.com",
        path: "/",
        secure: !0
      });
      const i = `${r.pathname}?${new URLSearchParams(n).toString()}`;
      globalThis.history.replaceState({}, "", i);
    }
    return;
  }
  if (C.test(globalThis.location.href) && (E in t && t[E] && f.set(u, t[E], {
    path: "/",
    domain: ".ridibooks.com",
    secure: !0
  }), f.get(u) && !(E in t))) {
    const o = `${r.pathname}?${new URLSearchParams({
      ...t,
      [E]: f.get(u)
    }).toString()}`;
    globalThis.history.replaceState({}, "", o);
  }
}
const ye = (e, r) => {
  T(() => {
    S(e);
  }, [e.referralEventData, ...r]);
};
var _ = {};
Object.defineProperty(_, "__esModule", { value: !0 });
_.parse = N;
_.serialize = b;
const O = /^[\u0021-\u003A\u003C\u003E-\u007E]+$/, v = /^[\u0021-\u003A\u003C-\u007E]*$/, x = /^([.]?[a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?)([.][a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?)*$/i, w = /^[\u0020-\u003A\u003D-\u007E]*$/, y = Object.prototype.toString, Y = /* @__PURE__ */ (() => {
  const e = function() {
  };
  return e.prototype = /* @__PURE__ */ Object.create(null), e;
})();
function N(e, r) {
  const t = new Y(), c = e.length;
  if (c < 2)
    return t;
  const o = (r == null ? void 0 : r.decode) || U;
  let n = 0;
  do {
    const i = e.indexOf("=", n);
    if (i === -1)
      break;
    const s = e.indexOf(";", n), a = s === -1 ? c : s;
    if (i > a) {
      n = e.lastIndexOf(";", i - 1) + 1;
      continue;
    }
    const I = p(e, n, i), d = h(e, i, I), P = e.slice(I, d);
    if (t[P] === void 0) {
      let D = p(e, i + 1, a), m = h(e, a, D);
      const A = o(e.slice(D, m));
      t[P] = A;
    }
    n = a + 1;
  } while (n < c);
  return t;
}
function p(e, r, t) {
  do {
    const c = e.charCodeAt(r);
    if (c !== 32 && c !== 9)
      return r;
  } while (++r < t);
  return t;
}
function h(e, r, t) {
  for (; r > t; ) {
    const c = e.charCodeAt(--r);
    if (c !== 32 && c !== 9)
      return r + 1;
  }
  return t;
}
function b(e, r, t) {
  const c = (t == null ? void 0 : t.encode) || encodeURIComponent;
  if (!O.test(e))
    throw new TypeError(`argument name is invalid: ${e}`);
  const o = c(r);
  if (!v.test(o))
    throw new TypeError(`argument val is invalid: ${r}`);
  let n = e + "=" + o;
  if (!t)
    return n;
  if (t.maxAge !== void 0) {
    if (!Number.isInteger(t.maxAge))
      throw new TypeError(`option maxAge is invalid: ${t.maxAge}`);
    n += "; Max-Age=" + t.maxAge;
  }
  if (t.domain) {
    if (!x.test(t.domain))
      throw new TypeError(`option domain is invalid: ${t.domain}`);
    n += "; Domain=" + t.domain;
  }
  if (t.path) {
    if (!w.test(t.path))
      throw new TypeError(`option path is invalid: ${t.path}`);
    n += "; Path=" + t.path;
  }
  if (t.expires) {
    if (!K(t.expires) || !Number.isFinite(t.expires.valueOf()))
      throw new TypeError(`option expires is invalid: ${t.expires}`);
    n += "; Expires=" + t.expires.toUTCString();
  }
  if (t.httpOnly && (n += "; HttpOnly"), t.secure && (n += "; Secure"), t.partitioned && (n += "; Partitioned"), t.priority)
    switch (typeof t.priority == "string" ? t.priority.toLowerCase() : void 0) {
      case "low":
        n += "; Priority=Low";
        break;
      case "medium":
        n += "; Priority=Medium";
        break;
      case "high":
        n += "; Priority=High";
        break;
      default:
        throw new TypeError(`option priority is invalid: ${t.priority}`);
    }
  if (t.sameSite)
    switch (typeof t.sameSite == "string" ? t.sameSite.toLowerCase() : t.sameSite) {
      case !0:
      case "strict":
        n += "; SameSite=Strict";
        break;
      case "lax":
        n += "; SameSite=Lax";
        break;
      case "none":
        n += "; SameSite=None";
        break;
      default:
        throw new TypeError(`option sameSite is invalid: ${t.sameSite}`);
    }
  return n;
}
function U(e) {
  if (e.indexOf("%") === -1)
    return e;
  try {
    return decodeURIComponent(e);
  } catch {
    return e;
  }
}
function K(e) {
  return y.call(e) === "[object Date]";
}
var L = /* @__PURE__ */ ((e) => (e.BUYER = "buyer", e.ALL = "all", e))(L || {});
const Ye = (e) => e ? _.parse(e) : null, Ne = (e, r, t) => {
  if (r === void 0) {
    document.cookie = _.serialize(e, "", {
      ...t,
      expires: /* @__PURE__ */ new Date(0)
    });
    return;
  }
  document.cookie = _.serialize(e, r, t);
}, be = (e) => e.length <= 3 ? e.replace(/.$/, "*") : e.substring(0, 3) + e.substring(3).replace(/./g, "*"), Ue = (e, r) => {
  if (!r)
    return 0;
  const t = 1 - e / r;
  return Math.floor((t + Number.EPSILON) * 100);
};
export {
  J as ACCESS_TOKEN_KEY,
  q as ADD_PWA_REQUESTED_KEY,
  M as ADULT_EXCLUDE_KEY,
  j as ADULT_INCLUDE_KEY,
  ee as AUTO_LOGIN_KEY,
  G as DEAUTH_WATCHDOG_SERVER_TOKEN_KEY,
  he as DEVICE_TYPE_ANDROID,
  me as DEVICE_TYPE_ANDROID_TABLET,
  Ce as DEVICE_TYPE_ETC_MOBILE,
  pe as DEVICE_TYPE_IPAD,
  De as DEVICE_TYPE_IPHONE,
  Re as DEVICE_TYPE_IPOD,
  te as DEVICE_TYPE_KEY,
  Se as DEVICE_TYPE_PAPER,
  Oe as DEVICE_TYPE_PAPER_LITE,
  ve as DEVICE_TYPE_PAPER_PRO,
  Ae as DEVICE_TYPE_PC,
  xe as DEVICE_TYPE_RIDIPAPER,
  we as DEVICE_TYPE_RIDIPAPER4,
  ge as DEVICE_TYPE_WINDOWS_CE,
  Te as DEVICE_TYPE_WINDOWS_PHONE,
  W as FINGERPRINT_KEY,
  B as FROM_ACCOUNT_MODIFY,
  le as INPAY,
  z as LOGIN_TIME_KEY,
  ce as LOGOUT_TIME_KEY,
  X as NAMESPACE,
  oe as PHP_SESSION_KEY,
  Pe as RDT_INFO,
  _e as REFERRAL_CODE,
  u as REFERRAL_COOKIE_KEY,
  E as REFERRAL_QUERY_KEY,
  Z as REFRESH_TOKEN_KEY,
  Q as REVIEW_TAB_KEY,
  Ie as RIDI_APP_APPSFLYER_ID,
  fe as RIDI_APP_FIREBASE_APP_ID,
  ue as RIDI_APP_GA_SESSION_ID,
  de as RIDI_APP_INSTANCE_ID,
  re as RIDI_APP_KEY,
  V as RIDI_APP_THEME_KEY,
  ne as RIDI_APP_VERSION_KEY,
  $ as RIDI_AUTH_INFO_KEY,
  ie as RIDI_FFID_KEY,
  F as RIDI_NAVIGATION_KEY,
  Ee as RUID,
  L as ReviewTab,
  se as SERVER_METRICS,
  ae as STORAGE_PREFIX,
  H as USE_GRID_BOOK_LISTING,
  Ue as getDiscountPercentage,
  Ye as getParsedCookie,
  be as maskingUserId,
  Ne as setCookie,
  S as syncReferralCode,
  ye as useSyncReferralCode
};
